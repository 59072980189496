const constants = {
  api: {
    HOST_URL: process.env.NEXT_PUBLIC_MILLMOLL_API_URL, //where the api routes are
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'authorization': "55CDA991966A565FBA16F6F87E3B9"
    },
  },
  app: {
    HOST_URL: process.env.NEXT_PUBLIC_BASE_API_URL, //where this projects routes are
    headers: {
      // 'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  root: {
    HOST_URL: `${process.env.NEXT_PUBLIC_BACKEND_URL}`, //where this projects routes are
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
};

export default constants;
