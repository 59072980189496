import axios from 'axios';
import constants from './constants';

const axiosClientApi = axios.create(); //api
axiosClientApi.defaults.baseURL = constants.api.HOST_URL;
axiosClientApi.defaults.headers = constants.api.headers;


// Configure axios in order to be able to make api requests to the laravel backend.
const axiosClientBase = axios.create(); //client
axiosClientBase.defaults.withCredentials = true;
axiosClientBase.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API_URL;


// Configure axios in order to be able to make api requests to the laravel backend.
const axiosRootProject = axios.create(); //client
axiosRootProject.defaults.withCredentials = true;
axiosRootProject.defaults.baseURL = constants.root.HOST_URL;
axiosRootProject.defaults.headers = constants.root.headers;

// Configure axios in order to be able to make api requests to the laravel backend.
// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API_URL;

/*
|--------------------------------------------------------------------------
| Protected Routes
|--------------------------------------------------------------------------
|
| A list of routes that is only accessible for authenticated user. If an
| unauthenticated user tries to access on of the listed routes, she will be
| redirected to /user/login. The list also respects sub-routes.
| This means, if you include /dashboard, /dashboard/analytics or /dashboard/1
| will lead to a redirect if the user is not authenticated.
|
*/
export const protectedRoutes = [
    process.env.NEXT_PUBLIC_USER_HOME_ROUTE, // -> from .env.local
    process.env.NEXT_PUBLIC_USER_CRM_ROUTE, // -> from .env.local
    // "/profile",
    // "/acount",
    // ...,
];

export const portalRoutes = [
    process.env.NEXT_PUBLIC_USER_HOME_ROUTE,
    '/login',
    '/register',
    '/verify',
    '/forgot',
    '/user',
    '/myaccount',
    '/dash',
    '/CRM'
    
];

export const fullPageFormRoutes = [
  '/login',
  '/register',
  '/user'
]



// To share cookies to cross site domain, change to true.
axiosClientApi.defaults.withCredentials = false;
// axiosClientBase.defaults.withCredentials = true;

export function getCustomRequest(URL) {
  return axios.get(`${URL}`).then(response => response);
}

export function postCustomRequest(URL, payload) {
  return axios.post(`${URL}`, payload).then(response => response);
}

export function getRequest(URL) {
  return axiosClientApi.get(`/${URL}`).then(response => response);
}

export function postRequest(URL, payload) {
  return axiosClientApi.post(`/${URL}`, payload).then(response => response);
}

export function patchRequest(URL, payload) {
  return axiosClientApi.patch(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
  return axiosClientApi.delete(`/${URL}`).then(response => response);
}

export function getBaseRequest(URL, headers = {}, options = {}) {
  return axiosClientBase.get(URL, {
    headers: headers,
    ...options
  }).then(response => response);
}

export function postBaseRequest(URL, payload) {
  return axiosClientBase.post(`${URL}`, payload).then(response => response);
}


export function getRootReq(URL) {
  return axiosRootProject.get(`${URL}`).then(response => response);
}

export function postRootReq(URL, payload) {
  return axiosRootProject.post(`${URL}`, payload).then(response => response);
}